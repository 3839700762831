/* eslint-disable react/prop-types */

import React, { Component } from "react";
import { AppContext } from "~context/AppContext";

class SideTextComponent extends Component {
  getTextTransform = () => {
    return `translate3d(0, 0, 0)`;
  };

  //

  render() {
    const { textLeft, textRight } = this.props;

    let { color } = this.props;

    if (!color || color === ``) {
      color = `black`;
    }

    return (
      <div
        className="side-text w-full h-full absolute top-0 right-0 bottom-0 left-0 z-30 flex items-center justify-between pointer-events-none"
        style={{
          transform: this.getTextTransform()
        }}
      >
        <h3
          className={`side-text__heading side-text__heading--left f1 flex items-center justify-center text-${color}`}
        >
          <span
            className="animation-appear-left"
            style={{ animationDelay: `150ms` }}
          >
            {textLeft || ``}
          </span>
        </h3>

        <h3
          className={`side-text__heading side-text__heading--right f1 flex items-center justify-center text-${color}`}
        >
          <span
            className="animation-appear-left"
            style={{ animationDelay: `150ms` }}
          >
            {textRight || ``}
          </span>
        </h3>
      </div>
    );
  }
}

const SideText = props => {
  return (
    <AppContext.Consumer>
      {appContext => (
        <SideTextComponent
          appContext={appContext}
          color={props.color}
          imageLeft={props.imageLeft}
          imageRight={props.imageRight}
          textLeft={props.textLeft}
          textRight={props.textRight}
          translatable={props.translatable}
        />
      )}
    </AppContext.Consumer>
  );
};

export default SideText;
