/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React, { Component } from "react";
import { graphql } from "gatsby";
import { AppContext } from "~context/AppContext";
import Layout from "~components/Layout";
import NewsletterForm from "~components/NewsletterForm";
import SEO from "~components/SEO";
import SideText from "~components/SideText";

class ContactPageComponent extends Component {
  componentDidMount() {}

  render() {
    const { appContext, frontmatter, location } = this.props;

    return (
      <>
        <SEO
          customTitle={frontmatter.title}
          customDescription={frontmatter.seoDescription}
          customKeywords={frontmatter.seoKeywords}
          path={location.pathname}
        />

        <Layout className="contact-page relative">
          <div className="contact-page__inner w-full relative flex items-center justify-center">
            <SideText color="white" textLeft="CONTACT" />

            <section className="w-full relative grid">
              <ul className="grid-end-4 sm:grid-end-8 grid-start-4 sm:grid-start-3">
                <li
                  className="animation-appear relative"
                  style={{ animationDelay: `100ms` }}
                >
                  <p className="mt-4 f5">Contact</p>

                  <p className="f5 text-grey">
                    <a href="mailto:info@hearmyeyes.com.au">
                      info@hearmyeyes.com.au
                    </a>
                  </p>
                </li>
              </ul>

              <div className="grid-end-4 sm:grid-end-8 grid-start-9 sm:grid-start-3">
                <div
                  className="animation-appear-left relative"
                  style={{ animationDelay: `300ms` }}
                >
                  <p className="mt-4 f5">social</p>

                  <ul>
                    <li className="f5 text-grey">
                      <a
                        href="https://www.instagram.com/hearmyeyes/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        instagram @hearmyeyes
                      </a>
                    </li>

                    <li className="f5 text-grey">
                      <a
                        href="https://www.facebook.com/hearmyeyes"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        facebook @hearmyeyes
                      </a>
                    </li>

                    <li className="f5 text-grey">
                      <a
                        href="https://vimeo.com/user75914432"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        vimeo @hearmyeyes
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="contact-page__mailing-list relative grid-end-8 sm:grid-end-9 grid-start-4 sm:grid-start-3 relative mt-24">
                <h3 className="relative flex mb-6 f3">
                  <span
                    className="animation-appear-left block mr-4 whitespace-no-wrap text-black"
                    style={{ animationDelay: `100ms` }}
                  >
                    join our mailing list
                  </span>

                  {appContext.device !== `mobile` && (
                    <span
                      className="animation-appear-right block whitespace-no-wrap text-white"
                      style={{ animationDelay: `200ms` }}
                    >
                      just the good stuff
                    </span>
                  )}
                </h3>

                <NewsletterForm />
              </div>
            </section>
          </div>
        </Layout>
      </>
    );
  }
}

const ContactPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <AppContext.Consumer>
      {appContext => (
        <ContactPageComponent
          appContext={appContext}
          frontmatter={frontmatter}
          location={location}
        />
      )}
    </AppContext.Consumer>
  );
};

export default ContactPage;

export const query = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        seoDescription
        seoKeywords
      }
    }
  }
`;
